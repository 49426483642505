define([], function () {
    'use strict';

    if (typeof window === 'undefined') {
        return;
    }

    const Element = window.Element;
    const ElementPrototype = window.Element.prototype;

    // reference under polyfill section: https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }

    // reference: https://github.com/jonathantneal/closest/
    if (typeof ElementPrototype.closest !== 'function') {
        ElementPrototype.closest = function closest(selector) {
            let element = this; // eslint-disable-line

            while (element && element.nodeType === 1) {
                if (element.matches(selector)) {
                    return element;
                }

                element = element.parentNode;
            }

            return null;
        };
    }
});
