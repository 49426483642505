define([
    'repeater/components/repeater',
    'componentsCore',

    // side effects
    'repeater/elementPolyfills'
], function (repeater, componentsCore) {
    'use strict';

    const repeaterCompType = 'wysiwyg.viewer.components.Repeater';

    componentsCore.compRegistrar.register(repeaterCompType, repeater);
});
