define([
    'componentsCore',
    'create-react-class',
    'lodash',
    'react',
    'reactDOM',
    'santa-components'
], function (
    componentsCore,
    createReactClass,
    _,
    React,
    ReactDOM,
    santaComponents
) {
    'use strict';

    const scrollMixin = santaComponents.mixins.scrollMixin;

    const alignmentToJustifyContent = {
        justify: 'space-between',
        right: 'flex-end',
        left: 'flex-start',
        center: 'center'
    };
    const supportedKeyboardKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    const gridCellSelector = '[role="gridcell"]';

    const handleKeyboardEvent = (evt, {tabbableElements, direction}) => {
        const shouldHandleKeyboardEvent = _.includes(supportedKeyboardKeys, evt.key) &&
            evt.target.tagName !== 'INPUT' &&
            evt.target.tagName !== 'SELECT';

        if (shouldHandleKeyboardEvent) {
            evt.preventDefault();

            const isGridCell = el => el.matches(gridCellSelector);
            const target = isGridCell(evt.target) ? evt.target : evt.target.closest(gridCellSelector);
            const focusedElementRow = Number(target.getAttribute('data-grid-row'));
            const focusedElementCol = Number(target.getAttribute('data-grid-col'));

            const getNumberOfRowsInColumn = col => _.filter(tabbableElements, el => isGridCell(el) && Number(el.getAttribute('data-grid-col')) === col).length;
            const getSameRowNextColumnLocation = () => {
                const nextColumnIndex = focusedElementCol + 1;
                const numberOfRowsInNextColumn = getNumberOfRowsInColumn(nextColumnIndex) - 1;
                return {
                    row: focusedElementRow > numberOfRowsInNextColumn ? numberOfRowsInNextColumn : focusedElementRow,
                    col: nextColumnIndex
                };
            };

            const getSameRowPreviousColumnLocation = () => ({
                row: focusedElementRow,
                col: focusedElementCol - 1
            });

            const evtKeyToNextFocusedItemLocationGetter = {
                ArrowDown: () => ({
                    row: focusedElementRow + 1,
                    col: focusedElementCol
                }),
                ArrowUp: () => ({
                    row: focusedElementRow - 1,
                    col: focusedElementCol
                }),
                ArrowRight: () => {
                    if (direction === 'rtl') {
                        return getSameRowPreviousColumnLocation();
                    }

                    return getSameRowNextColumnLocation();
                },
                ArrowLeft: () => {
                    if (direction === 'rtl') {
                        return getSameRowNextColumnLocation();
                    }

                    return getSameRowPreviousColumnLocation();
                },
                Home: () => ({
                    row: 0,
                    col: focusedElementCol
                }),
                End: () => ({
                    row: getNumberOfRowsInColumn(focusedElementCol) - 1,
                    col: focusedElementCol
                })
            };

            const {row, col} = evtKeyToNextFocusedItemLocationGetter[evt.key]();
            const nextElementToFocus = _.find(tabbableElements, el =>
                Number(el.getAttribute('data-grid-row')) === row && Number(el.getAttribute('data-grid-col')) === col
            );

            if (nextElementToFocus) {
                nextElementToFocus.focus();
                componentsCore.utils.accessibility.addFocusRingClass(nextElementToFocus);
            }
        }
    };

    function shouldSkipRenderingChildren({templateLayout, isMobileView, isInSSR}) {
        const isDocked = !!templateLayout.docked;

        return isInSSR && !isMobileView && isDocked;
    }

    const getHorizontalGap = ({alignment, gap}) => alignment !== 'justify' ? gap.horizontal : 0;

    const getNumberOfColumns = (repeaterWidth, itemWidth, horizontalGap, numberOfChildren) => {
        if (repeaterWidth < itemWidth) {
            return 1;
        }

        const numberOfColumnsBasedOnWidth = Math.floor((repeaterWidth - itemWidth) / (itemWidth + horizontalGap)) + 1;

        return _.min([numberOfChildren, numberOfColumnsBasedOnWidth]);
    };

    const getEmptyColumns = numberOfColumns => {
        const columns = [];

        for (let i = 0; i < numberOfColumns; i++) {
            columns[i] = [];
        }

        return columns;
    };

    const groupByNumberOfColumns = (items, numberOfColumns) => _.reduce(items, function (itemsInColumns, item, index) {
        const columnIndex = index % numberOfColumns;
        itemsInColumns[columnIndex].push(item);

        return itemsInColumns;
    }, getEmptyColumns(numberOfColumns));

    const getRepeaterChildrenStyle = compProp => {
        const horizontalGap = getHorizontalGap(compProp);
        const verticalGap = compProp.gap.vertical;

        return {
            margin: `${verticalGap / 2}px ${(horizontalGap || 0) / 2}px`,
            direction: 'ltr'
        };
    };

    const getFullWidthRepeaterChildrenInSEO = ({children, compProp}) =>
        React.Children.map(children, child => santaComponents.utils.createReactElement('li', {
            key: child.props.id,
            style: getRepeaterChildrenStyle(compProp)
        }, child));

    const getListItems = ({id, children, compProp, templateLayout, getTabbableElements}) => {
        const horizontalGap = getHorizontalGap(compProp);
        const numberOfColumns = getNumberOfColumns(templateLayout.repeaterWidth, templateLayout.templateWidth, horizontalGap, children.length);
        const groupedChildren = groupByNumberOfColumns(children, numberOfColumns);

        return _.map(groupedChildren, (currGroupChildren, columnIndex) => {
            const groupReactChildren = React.Children.map(currGroupChildren, (child, childIndexInGroup) => santaComponents.utils.createReactElement('div', {
                key: child.props.id,
                role: 'gridcell',
                'data-grid-row': childIndexInGroup,
                'data-grid-col': columnIndex,
                'data-repeater-id': id,
                onKeyDown: evt => {
                    handleKeyboardEvent(evt, {tabbableElements: getTabbableElements(), direction: compProp.direction});
                    componentsCore.utils.accessibility.keyboardInteractions.activateByEnterButton(evt, evt.target.firstChild);
                },
                tabIndex: columnIndex === 0 && childIndexInGroup === 0 ? 0 : -1,
                style: getRepeaterChildrenStyle(compProp)
            }, child));
            return santaComponents.utils.createReactElement('div', {
                id: `${id}-col${columnIndex}`,
                key: `${id}-col${columnIndex}`,
                role: 'row',
                style: {display: 'flex', flexDirection: 'column'}
            }, groupReactChildren);
        });
    };

    const getMeshChildren = (props, getTabbableElements) => {
        if (shouldSkipRenderingChildren(props)) {
            return null;
        }

        if (_.isEmpty(props.children)) {
            return props.children;
        }

        const children = props.isQAMode ? React.Children.map(props.children, (child, index) => React.cloneElement(child, {'data-index': index})) : props.children;

        return getListItems({
            id: props.id,
            children,
            compProp: props.compProp,
            templateLayout: props.templateLayout,
            getTabbableElements
        });
    };

    const getResponsiveChildren = ({children, isQAMode}) => React.Children.map(children, (child, index) => React.cloneElement(child, _.assign({
        isListItem: true
    }, isQAMode && {'data-index': index})));

    const getRepeaterContainerStyle = ({alignment, gap, direction}) => {
        const horizontalGap = getHorizontalGap({alignment, gap});
        const verticalGap = gap.vertical;

        return {
            display: 'flex',
            position: 'relative',
            flexWrap: 'wrap',
            justifyContent: alignmentToJustifyContent[alignment],
            margin: `${-verticalGap / 2}px ${-(horizontalGap || 0) / 2}px`,
            direction
        };
    };

    const overflowWrapperClass = createReactClass({
        displayName: 'overflowWrapper',
        render() {
            return santaComponents.utils.createReactElement('div', this.props);
        }
    });
    
    /**
     * @class components.Repeater
     * @extends {core.skinBasedComp}
     */
    const repeater = {
        displayName: 'Repeater',
        mixins: [componentsCore.mixins.skinBasedComp, scrollMixin, santaComponents.mixins.overflowWrapperMixin],
        statics: {
            compSpecificIsDomOnlyOverride: () => false,
            behaviors: {
                scrollForward: {methodName: 'scrollForward'},
                scrollBackward: {methodName: 'scrollBackward'}
            }
        },
        propTypes: {
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            rootId: santaComponents.santaTypesDefinitions.Component.rootId.isRequired,
            layoutContainerClassName: santaComponents.santaTypesDefinitions.Layout.layoutContainerClassName,
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            templateLayout: santaComponents.santaTypesDefinitions.Repeater.templateLayout,
            setDisplayedOnlyCompsTemplateId: santaComponents.santaTypesDefinitions.Repeater.setDisplayedOnlyCompsTemplateId.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            clearDisplayedOnlyCompsTemplateId: santaComponents.santaTypesDefinitions.Repeater.clearDisplayedOnlyCompsTemplateId.isRequired,
            isInSSR: santaComponents.santaTypesDefinitions.isInSSR.isRequired,
            isInSeo: santaComponents.santaTypesDefinitions.isInSeo.isRequired,
            isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
            isQAMode: santaComponents.santaTypesDefinitions.isQAMode
        },

        componentWillMount() {
            this.props.setDisplayedOnlyCompsTemplateId(this.props.id, this.props.compData.items[0]);
        },

        componentWillUnmount() {
            this.props.clearDisplayedOnlyCompsTemplateId(this.props.id);
        },

        getTabbableElements() {
            return componentsCore.utils.accessibility.getTabbaleElements(ReactDOM.findDOMNode(this), true);
        },

        scrollForward() {
            this.scrollInnerContent('forward');
        },

        scrollBackward() {
            this.scrollInnerContent('backward');
        },

        getMeshSkinProperties() { // eslint-disable-line complexity
            const {docked} = this.props.templateLayout;
            const isFullWidth = !!docked;
            const dockedPxMargin = _.get(docked, 'left.px', 0);
            const HideHorizontalOverflowWrapper = React.createElement.bind(null, overflowWrapperClass);
            HideHorizontalOverflowWrapper.type = overflowWrapperClass;

            const MAX_SPACE_BETWEEN = 100;

            return {
                '': _.assign({
                    style: _.merge({height: 'auto'}, this.props.inlineParentStyle)
                }, shouldSkipRenderingChildren(this.props) && {
                    'data-ssrignorediverge': true
                }, this.props.compData.a11y && {
                    'aria-label': this.props.compData.a11y.label,
                    role: this.props.compData.a11y.role
                }),
                inlineContent: _.assign({
                    parentConst: santaComponents.utils.createReactElement.bind(null, 'div'),
                    role: 'grid',
                    style: _.assign(getRepeaterContainerStyle(this.props.compProp), isFullWidth && {pointerEvents: 'initial'}),
                    children: getMeshChildren(this.props, this.getTabbableElements)
                }, isFullWidth && {
                    wrap: [HideHorizontalOverflowWrapper, {
                        id: `#${this.props.id}overflowWrapper`,
                        style: {
                            overflow: 'hidden',
                            padding: `${MAX_SPACE_BETWEEN / 2}px ${dockedPxMargin}px`,
                            margin: `${-MAX_SPACE_BETWEEN / 2}px ${-dockedPxMargin}px`,
                            pointerEvents: 'none'
                        }
                    }]
                })
            };
        },

        getResponsiveProperties() {
            return {
                inlineContent: this.getInlineContent({
                    role: 'list',
                    parentConst: santaComponents.utils.createReactElement.bind(null, 'div'),
                    children: getResponsiveChildren(this.props)
                })
            };
        },

        getFullWidthRepeaterPropertiesInSEO() {
            return {
                '': {
                    style: {
                        height: 'auto'
                    }
                },
                inlineContent: {
                    parentConst: santaComponents.utils.createReactElement.bind(null, 'ul'),
                    style: getRepeaterContainerStyle(this.props.compProp),
                    children: getFullWidthRepeaterChildrenInSEO(this.props)
                }
            };
        },

        getSkinProperties() {
            const isFullWidth = !!this.props.templateLayout.docked;
            if (this.props.isInSeo && !this.props.isMobileView && isFullWidth) {
                return this.getFullWidthRepeaterPropertiesInSEO();
            }

            if (this.props.isResponsive) {
                return this.getResponsiveProperties();
            }

            return this.getMeshSkinProperties();
        }
    };

    return repeater;
});
